/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import customersTableData from "layouts/tables/data/customersTableData";
import { useState } from "react";
import MDSnackbar from "components/MDSnackbar";
import AdminControls from "./CreateAccount";

function Tables() {
  const { columns, rows, loading, error, refetchData } = customersTableData();
  const [userInfo, setUserInfo] = useState(localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null);
  // States for notification bars
  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState({ color: "success", content: "Notification", dateTime: "Just Now", icon: "check" });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AdminControls
        refetchData={refetchData}
        setNotificationContent={setNotificationContent}
        setShowNotification={setShowNotification}
        userInfo={userInfo}
      />

      <MDSnackbar
        color={notificationContent.color}
        icon={notificationContent.icon}
        title="H.M. Agent Portal"
        content={notificationContent.content}
        dateTime={notificationContent.dateTime}
        open={showNotification}
        onClose={() => setShowNotification(false)}
        close={() => setShowNotification(false)}
        bgWhite
      />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Customers Data
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                  </Box>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    showTotalEntries={true}
                    canSearch
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
