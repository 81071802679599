import React, { useState } from "react";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import { ArrowDropDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import AgentDialog from "components/Dialogs/AgentDialog";
import CustomerDialog from "components/Dialogs/CustomerDialog";

function AdminControls({
    setNotificationContent,
    setShowNotification,
    userInfo,
    refetchData,
}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [openAgentDialog, setOpenAgentDialog] = useState(false);
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAgentClick = () => {
        handleClose();
        setOpenAgentDialog(true);
    };

    const handleCustomerClick = () => {
        handleClose();
        setOpenCustomerDialog(true);
    };

    const handleAgentDialogClose = () => {
        setOpenAgentDialog(false);
    };

    const handleCustomerDialogClose = () => {
        setOpenCustomerDialog(false);
    };


    return (
        <>
            <MDButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                endIcon={<ArrowDropDown />}
            >
                New
            </MDButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {userInfo.role === 'admin' && <MenuItem onClick={handleAgentClick}>Agent</MenuItem>}
                <MenuItem onClick={handleCustomerClick}>Customer</MenuItem>
            </Menu>
            <AgentDialog
                openAgentDialog={openAgentDialog}
                handleAgentDialogClose={handleAgentDialogClose}
                setNotificationContent={setNotificationContent}
                setShowNotification={setShowNotification}
            />
            <CustomerDialog
                openCustomerDialog={openCustomerDialog}
                handleCustomerDialogClose={handleCustomerDialogClose}
                userInfo={userInfo}
                setNotificationContent={setNotificationContent}
                setShowNotification={setShowNotification}
                refetchData={refetchData}
            />
        </>
    );
}

AdminControls.propTypes = {
    setNotificationContent: PropTypes.func.isRequired,
    setShowNotification: PropTypes.func.isRequired,
    userInfo: PropTypes.object.isRequired,
    refetchData: PropTypes.func.isRequired,
};

export default AdminControls;