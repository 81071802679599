// ActionMenuRoot.js
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// Custom styles for ActionMenu components
export const ActionMenuContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  // Customize menu styles here
  '& .MuiMenu-paper': {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  // Customize menu item styles here
  color: theme.palette.text.primary,
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    color: theme.palette.background.paper
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 140,
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const StyledList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
