import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { createPortalAccount } from 'api/authentication';

const AgentDialog = ({ openAgentDialog, handleAgentDialogClose, setShowNotification, setNotificationContent }) => {

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required').min(5, "Password must be at least 5 characters"),
        first_name: Yup.string().required("First Name is required"),
        last_name: Yup.string(),
        role_name: Yup.string().required("Role Name is required")
    });

    const handleAgentSubmit = async (values) => {
        const res = await createPortalAccount(values, true);
        if (res.status === 201) {
            setNotificationContent({
                color: "success",
                content: "Account has been created",
                dateTime: "Just Now",
                icon: "check"
            });
        }
        else {
            setNotificationContent({
                color: "error",
                content: res.data.message,
                dateTime: "Just Now",
                icon: "warning"
            });
        }
        setShowNotification(true);
        handleAgentDialogClose();
    };

    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
                first_name: '',
                last_name: '',
                role_name: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleAgentSubmit}
        >
            {({ errors, touched }) => (
                <Dialog open={openAgentDialog} onClose={handleAgentDialogClose}>
                    <Form>
                        <DialogTitle>Create Agent Account</DialogTitle>
                        <DialogContent>
                            <Field
                                as={TextField}
                                autoFocus
                                margin="dense"
                                id="username"
                                name="username"
                                label="Username"
                                type="text"
                                fullWidth
                                error={touched.username && Boolean(errors.username)}
                                helperText={touched.username && errors.username}
                            />
                            <Field
                                as={TextField}
                                margin="dense"
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                fullWidth
                                error={touched.password && Boolean(errors.password)}
                                helperText={touched.password && errors.password}
                            />
                            <Field
                                as={TextField}
                                margin="dense"
                                id="first_name"
                                name="first_name"
                                label="First Name"
                                type="text"
                                fullWidth
                                error={touched.first_name && Boolean(errors.first_name)}
                                helperText={touched.first_name && errors.first_name}
                            />
                            <Field
                                as={TextField}
                                margin="dense"
                                id="last_name"
                                name="last_name"
                                label="Last Name"
                                type="text"
                                fullWidth
                                error={touched.last_name && Boolean(errors.last_name)}
                                helperText={touched.last_name && errors.last_name}
                            />
                            <FormControl key="role_name" fullWidth margin="dense">
                                <InputLabel id={`role_name-label`}>Role Name</InputLabel>
                                <Field
                                    as={Select}
                                    variant="standard"
                                    labelId={`role_name-label`}
                                    name={"role_name"}
                                    error={touched["role_name"] && Boolean(errors["role_name"])}
                                >
                                    <MenuItem key="agent" value="agent">
                                        Agent
                                    </MenuItem>
                                    <MenuItem key="admin" value="admin">
                                        Admin
                                    </MenuItem>
                                </Field>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={handleAgentDialogClose}>Cancel</MDButton>
                            <MDButton type="submit" variant="contained" color="info">
                                Create Agent Account
                            </MDButton>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </Formik>
    );
}

AgentDialog.propTypes = {
    openAgentDialog: PropTypes.bool.isRequired,
    handleAgentDialogClose: PropTypes.func.isRequired,
    setShowNotification: PropTypes.func.isRequired,
    setNotificationContent: PropTypes.func.isRequired,
};

export default AgentDialog;
