import { Outlet, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { clearCredentials } from 'slices/authSlice';
import { useEffect } from 'react';

const PrivateRoute = () => {
    const { userInfo } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        const token = userInfo?.token;
        if (token) {
            const decodedToken = jwtDecode(token);
            if (decodedToken.exp * 1000 < new Date().getTime()) dispatch(clearCredentials());
        }
    }, []);

    return userInfo ? <Outlet /> : <Navigate to="/authentication/sign-in" replace />
}

export default PrivateRoute;