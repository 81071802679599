import axiosInstance, { setAuthToken } from "./config";

export const createPortalAccount = async (formData, isAgent) => {
  try {
    const res = await axiosInstance.post(isAgent ? "/agents/sign-up" : "/users/sign-up", formData);
    return res;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const login = async (username, password) => {
  try {
    const res = await axiosInstance.post("/agents/login", { username, password });
    setAuthToken(res.data.token)
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};