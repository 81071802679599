// axios configuration (config.js)
import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: 'https://api.hollymovies.org/api', 
  baseURL: 'http://localhost:5555/api',
});

export const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token); // Persist token in localStorage
  } else {
    delete axiosInstance.defaults.headers.common['authorization'];
    localStorage.removeItem('token'); // Remove token if not provided
  }
};

// Load token when the app starts
const token = JSON.parse(localStorage.getItem('userInfo'))?.token;
if (token) {
  setAuthToken(token);
}

export default axiosInstance;
