import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { createPortalAccount } from 'api/authentication';

const CustomerDialog = ({ openCustomerDialog, handleCustomerDialogClose, userInfo, setShowNotification, setNotificationContent, refetchData }) => {
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required').min(5, "Password must be at least 5 characters"),
        number: Yup.string().matches(/^[0-9]+$/, "Phone number must only contain digits")
            .required('Phone number is required').min(6, "Number is too short").max(15, "Number is too long"),
        withdrawal_password: Yup.string().required('Withdrawal Password is required').min(5, "Withdrawal Password must be at least 5 characters"),
        first_name: Yup.string().required("First Name is required"),
        last_name: Yup.string(),
        invitationCode: Yup.string().required("Invitation Code is required"),
        tier_name: Yup.string().required('Tier name is required')
    });

    const handleCustomerSubmit = async (values) => {
        const res = await createPortalAccount(values, false);
        if (res.status === 201) {
            setNotificationContent({
                color: "success",
                content: "Account has been created",
                dateTime: "Just Now",
                icon: "check"
            });
            refetchData();
        }
        else {
            setNotificationContent({
                color: "error",
                content: res.data.message,
                dateTime: "Just Now",
                icon: "warning"
            });
        }
        setShowNotification(true);
        handleCustomerDialogClose();
    };

    const tierList = ['Basic', 'Silver', 'Gold', 'Platinum'];

    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
                withdrawal_password: '',
                first_name: '',
                last_name: '',
                invitationCode: userInfo?.invitationCode,
                tier_name: tierList[0]
            }}
            validationSchema={validationSchema}
            onSubmit={handleCustomerSubmit}
        >
            {({ errors, touched }) => (
                <Dialog open={openCustomerDialog} onClose={handleCustomerDialogClose}>
                    <Form>
                        <DialogTitle>Create Customer Account</DialogTitle>
                        <DialogContent>
                            <Field
                                as={TextField}
                                autoFocus
                                margin="dense"
                                id="username"
                                name="username"
                                label="Username"
                                type="text"
                                fullWidth
                                error={touched.username && Boolean(errors.username)}
                                helperText={touched.username && errors.username}
                            />
                            <Field
                                as={TextField}
                                margin="dense"
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                fullWidth
                                error={touched.password && Boolean(errors.password)}
                                helperText={touched.password && errors.password}
                            />
                            <Field
                                as={TextField}
                                margin="dense"
                                id="number"
                                name="number"
                                label="Number"
                                type="text"
                                fullWidth
                                error={touched.number && Boolean(errors.number)}
                                helperText={touched.number && errors.number}
                            />
                            <Field
                                as={TextField}
                                margin="dense"
                                id="withdrawal_password"
                                name="withdrawal_password"
                                label="Withdrawal Password"
                                type="password"
                                fullWidth
                                error={touched.withdrawal_password && Boolean(errors.withdrawal_password)}
                                helperText={touched.withdrawal_password && errors.withdrawal_password}
                            />
                            <Field
                                as={TextField}
                                margin="dense"
                                id="first_name"
                                name="first_name"
                                label="First Name"
                                type="text"
                                fullWidth
                                error={touched.first_name && Boolean(errors.first_name)}
                                helperText={touched.first_name && errors.first_name}
                            />
                            <Field
                                as={TextField}
                                margin="dense"
                                id="last_name"
                                name="last_name"
                                label="Last Name"
                                type="text"
                                fullWidth
                                error={touched.last_name && Boolean(errors.last_name)}
                                helperText={touched.last_name && errors.last_name}
                            />
                            <Field
                                as={TextField}
                                margin="dense"
                                id="invitationCode"
                                name="invitationCode"
                                label="Invitation Code"
                                type="text"
                                fullWidth
                                error={touched.invitationCode && Boolean(errors.invitationCode)}
                                helperText={touched.invitationCode && errors.invitationCode}
                            />
                            <FormControl key="tier_name" fullWidth margin="dense">
                                <InputLabel id={`tier_name-label`}>Tier Name</InputLabel>
                                <Field
                                    as={Select}
                                    variant="standard"
                                    labelId={`tier_name-label`}
                                    name="tier_name"
                                    error={touched.tier_name && touched.tier_name && Boolean(errors.tier_name)}
                                >
                                    {tierList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={handleCustomerDialogClose}>Cancel</MDButton>
                            <MDButton type="submit" variant="contained" color="info">
                                Create Customer Account
                            </MDButton>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </Formik>
    );
}

CustomerDialog.propTypes = {
    openCustomerDialog: PropTypes.bool.isRequired,
    handleCustomerDialogClose: PropTypes.func.isRequired,
    userInfo: PropTypes.object.isRequired,
    setShowNotification: PropTypes.func.isRequired,
    setNotificationContent: PropTypes.func.isRequired,
    refetchData: PropTypes.func.isRequired
};

export default CustomerDialog;
