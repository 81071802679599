import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff, Add, Remove } from '@mui/icons-material';
import { updateCustomerInfo } from 'api/customers';
import MDTypography from 'components/MDTypography';

function TabPanel(props) {
    const { children, value, index } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`edit-tabpanel-${index}`}
            aria-labelledby={`edit-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    )
}

function a11yProps(index) {
    return {
        id: `edit-tab-${index}`,
        'aria-controls': `edit-tabpanel-${index}`,
    }
}

function cleanNullValues(obj) {
    // Check if the value is an object or an array
    if (Array.isArray(obj)) {
        return obj.map(cleanNullValues);
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            acc[key] = cleanNullValues(obj[key]);
            return acc;
        }, {});
    }
    // Replace null with empty string
    return obj === null ? '' : obj;
}

const fields = {
    customerInfo: [
        { name: 'username', label: 'Name', type: 'text' },
        { name: 'password', label: 'Password', type: 'password' },
        { name: 'number', label: 'Phone', type: 'tel' },
        { name: 'balance', label: 'Balance', type: 'number' },
        { name: 'trust_point', label: 'Trust Point', type: 'number' },
    ],
    walletInfo: [
        { name: 'wallet_name', label: 'Wallet Name', type: 'text' },
        { name: 'wallet_address', label: 'Wallet Address', type: 'text' },
        { name: 'wallet_phone_number', label: 'Wallet Phone Number', type: 'tel' },
        { name: 'withdrawal_password', label: 'Withdrawal Password', type: 'password' },
        {
            name: 'currency1',
            label: 'Currency 1',
            type: 'select',
            options: ['', 'TRC20', 'ERC20'],
        },
        {
            name: 'currency2',
            label: 'Currency 2',
            type: 'select',
            options: ['', 'USDT', 'USDC', 'ETH', 'BTC'],
        },
    ],
    tierInfo: [
        {
            name: 'tier_name',
            label: 'Tier Name',
            type: 'select',
            options: ['Basic', 'Silver', 'Gold', 'Platinum'],
        },
    ],
};


const EditDialog = ({ data, openEditDialog, setOpenEditDialog, setShowNotification, setNotificationContent, refetchData }) => {
    data.balance = 0;
    const formInitialValues = cleanNullValues(data);
    const [activeTab, setActiveTab] = React.useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [isAdding, setIsAdding] = useState(true);
    console.log(formInitialValues.balance);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Utility function to find changed fields and return them as a single JSON object
    const getChangedFields = (initialValues, values) => {
        return Object.keys(initialValues).reduce((acc, key) => {
            if (initialValues[key] !== values[key]) {
                acc[key] = values[key]; // Add the changed field and its new value to the result object
            }
            return acc;
        }, {});
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required').min(5, "Password must be atleast characters"),
        number: Yup.string().matches(/^[0-9]+$/, "Phone number must only contain digits")
            .required('Phone number is required').min(6, "Number is too short").max(15, "Number is too long"),
        wallet_amount: Yup.number().required('Wallet Balance is required').min(0, 'Wallet Balance cannot be negative'),
        trust_point: Yup.number().required('Wallet Balance is required').min(0, 'Wallet Balance cannot be negative'),

        wallet_name: Yup.string(),
        wallet_address: Yup.string(),
        wallet_phone_number: Yup.string().matches(/^[0-9]+$/, "Phone number must only contain digits")
            .min(6, "Number is too short").max(15, "Number is too long"),

        tier_name: Yup.string().required('Tier name is required'),
    });

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleSubmit = async (values) => {
        const changedFields = { ...getChangedFields(formInitialValues, values), customer_id: data.customer_id };
        console.log('Changed Fields:', changedFields);
        const res = await updateCustomerInfo(changedFields);
        if (res.status === 200) {
            setNotificationContent({
                color: "success",
                content: "Changes have been saved",
                dateTime: "Just Now",
                icon: "check"
            });
            refetchData();
        }
        else {
            setNotificationContent({
                color: "error",
                content: response.data.message,
                dateTime: "Just Now",
                icon: "warning"
            });
        }

        setShowNotification(true);
        setOpenEditDialog(false);
    };

    return (
        <Dialog
            open={openEditDialog}
            onClose={() => setOpenEditDialog(false)}
            aria-labelledby="edit-dialog-title"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="edit-dialog-title">Edit User Information</DialogTitle>
            <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched }) => (
                    <Form>
                        <DialogContent>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={activeTab} onChange={handleTabChange} aria-label="edit user information tabs">
                                    <Tab label="Customer Information" {...a11yProps(0)} />
                                    <Tab label="Wallet Account Information" {...a11yProps(1)} />
                                    <Tab label="Tier Information" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            {activeTab === 0 && (
                                <Box sx={{ p: 3 }}>
                                    {fields.customerInfo.map((field) => {
                                        if (field.name === 'balance') {
                                            return (
                                                <div key={field.name}>
                                                    <p className='text-sm font-sm'>Current Amount: USDC {formInitialValues.wallet_amount}</p>
                                                    <p>Freeze Amount: USDC {(Number(formInitialValues.wallet_amount) + (isAdding ? 1 : -1) * Number(values.balance || 0)).toFixed(2)}</p>
                                                    <p>Balance: USDC {(Number(formInitialValues.negative_amount) + (isAdding ? 1 : -1) * Number(values.balance || 0)).toFixed(2)}</p>
                                                    <div className="flex mt-1">
                                                        <Field
                                                            as={TextField}
                                                            margin="dense"
                                                            name={field.name}
                                                            label={field.label}
                                                            type="number"
                                                            fullWidth
                                                            error={touched[field.name] && Boolean(errors[field.name])}
                                                            helperText={touched[field.name] && errors[field.name]}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onClick={() => setIsAdding(!isAdding)}
                                                                            edge="end"
                                                                        >
                                                                            {isAdding ? <Add /> : <Remove />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }

                                        return (
                                            <Field
                                                key={field.name}
                                                as={TextField}
                                                margin="dense"
                                                name={field.name}
                                                label={field.label}
                                                type={field.type === 'password' && showPassword ? 'text' : field.type}
                                                fullWidth
                                                error={touched[field.name] && Boolean(errors[field.name])}
                                                helperText={touched[field.name] && errors[field.name]}
                                                InputProps={field.type === 'password' ? {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={togglePasswordVisibility} edge="end">
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                } : null}
                                            />
                                        );
                                    })}
                                </Box>
                            )}
                            {activeTab === 1 && (
                                <Box sx={{ p: 3 }}>
                                    {fields.walletInfo.map((field) => (
                                        field.type == 'select' ? (
                                            <FormControl key={field.name} fullWidth margin="dense">
                                                <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                                                <Field
                                                    as={Select}
                                                    variant="standard"
                                                    labelId={`${field.name}-label`}
                                                    name={field.name}
                                                    error={touched[field.name] && Boolean(errors[field.name])}
                                                >
                                                    {field.options.map((option) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                            </FormControl>) : (
                                            <Field
                                                key={field.name}
                                                as={TextField}
                                                margin="dense"
                                                name={field.name}
                                                label={field.label}
                                                type={field.type === 'password' && showPassword ? 'text' : field.type}
                                                fullWidth
                                                error={touched[field.name] && Boolean(errors[field.name])}
                                                helperText={touched[field.name] && errors[field.name]}
                                                InputProps={field.type === 'password' ? {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={togglePasswordVisibility}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                } : null}
                                            />)
                                    ))}
                                </Box>
                            )}
                            {activeTab === 2 && (
                                <Box sx={{ p: 3 }}>
                                    {fields.tierInfo.map((field) => (
                                        <FormControl key={field.name} fullWidth margin="dense">
                                            <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                                            <Field
                                                as={Select}
                                                variant="standard"
                                                labelId={`${field.name}-label`}
                                                name={field.name}
                                                error={touched.tier && touched.tier.tier_name && Boolean(errors.tier?.tier_name)}
                                            >
                                                {field.options.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </FormControl>
                                    ))}
                                </Box>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
                            <Button type="submit" variant="contained" color="primary">
                                Save Changes
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};


// Add PropTypes validation
EditDialog.propTypes = {
    data: PropTypes.object.isRequired,
    openEditDialog: PropTypes.bool.isRequired,
    setOpenEditDialog: PropTypes.func.isRequired,
    setShowNotification: PropTypes.func.isRequired,
    setNotificationContent: PropTypes.func.isRequired,
    refetchData: PropTypes.func.isRequired
};
TabPanel.propTypes = {
    value: PropTypes.number,
    index: PropTypes.number,
    children: PropTypes.node
}

export default EditDialog;