import axiosInstance from "./config";

export const customersInfo = async () => {
  try {
    const res = await axiosInstance.get("/agents/get-customers-info");
    console.log(res.data)
    return res.data;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const getPremiumMovies = async (customerId) => {
  try {
    const res = await axiosInstance.get("agents/get-premium", { params: { customerId } });
    console.log(res.data);
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const setPremiumMovies = async (customerId, startIndex, movieIds) => {
  try {
    const res = await axiosInstance.post("agents/set-premium", { customerId, startIndex, movieIds });
    console.log(res.data)
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const updateCustomerInfo = async (data) => {
  try {
    const res = await axiosInstance.post("agents/update-customer-info", data);
    console.log(res.data)
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const getPendingWithdrawal = async (count) => {
  try {
    const res = await axiosInstance.get(`/agents/pending-withdrawal${count ? '?count=true' : ''}`);
    console.log(res.data)
    return res.data;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const updateWithdrawalStatus = async (data) => {
  try {
    const res = await axiosInstance.post("agents/update-status", data);
    console.log(res.data)
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};

export const resetSession = async () => {
  try {
    const res = await axiosInstance.post("agents/reset-session");
    console.log(res.data)
    return res;
  } catch (error) {
    console.error(error.response.data);
  }
};