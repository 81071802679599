// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// Images
import team2 from "assets/images/team-2.jpg";
import { getPendingWithdrawal } from "api/customers";
import { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import PropTypes from "prop-types";
import { updateWithdrawalStatus } from "api/customers";

export default function data() {
    const Customer = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            {/* <MDAvatar src={image} name={name} size="sm" /> */}
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );
    Customer.propTypes = {
        image: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    };

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userInfo, setUserInfo] = useState(localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null);

    const handleUpdateWithdrawalStatus = async (w_id, status) => {
        const res = await updateWithdrawalStatus({ w_id, status });
        setData((prevData) =>
            prevData.map((withdrawal) =>
                withdrawal.w_id === w_id
                    ? { ...withdrawal, statusMessage: res.data.message, statusColor: (res.status === 200 && status === 'accepted') ? 'success' : 'error', showMessage: true }
                    : withdrawal
            )
        );
        setTimeout(() => {
            setData((prevData) => prevData.filter((withdrawal) => withdrawal.w_id !== w_id));
        }, 3000);
    };

    useEffect(() => {
        // Set up SSE connection to listen for new notifications
        const eventSource = new EventSource(`https://api.hollymovies.org/api/users/subscribe?token=${userInfo.token}`);

        eventSource.onmessage = (event) => {
            const newRequest = JSON.parse(event.data);
            setData((prevData) => [newRequest, ...prevData]);
        };

        eventSource.onerror = (error) => {
            console.error('SSE connection error:', error);
        };

        // Clean up the SSE connection when the component unmounts
        return () => {
            eventSource.close();
        };
    }, []);

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await getPendingWithdrawal();
                console.log(result);
                setData(result || []);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        getData(); // Call the async function
    }, []); // Empty dependency array means this useEffect runs once when the component mounts

    if (loading) {
        return { columns: [], rows: [], loading };
    }

    if (error) {
        return { columns: [], rows: [], error };
    }

    return {
        columns: [
            { Header: "customer", accessor: "customer", align: "left" },
            { Header: "balance", accessor: "balance", align: "left" },
            { Header: "withdrawal amount", accessor: "withdrawal_amount", align: "left" },
            { Header: "action", accessor: "action", align: "center" },
        ],

        rows: data.map((withdrawal) => ({
            customer: {
                component: (<Customer image={team2} name={`${withdrawal.customer.first_name} ${withdrawal.customer.last_name}`} email={withdrawal.customer.username} />),
                text: `${withdrawal.customer.first_name} ${withdrawal.customer.last_name} ${withdrawal.customer.username}`
            },
            balance: {
                component: (<MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">${withdrawal.customer.wallet_amount}</MDTypography>),
                text: withdrawal.customer.wallet_amount.toString()
            },
            withdrawal_amount: {
                component: (<MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">${withdrawal.amount}</MDTypography>),
                text: withdrawal.amount.toString()
            },
            action: withdrawal.showMessage ? (
                <MDTypography variant="caption" color={withdrawal.statusColor}>
                    {withdrawal.statusMessage}
                </MDTypography>
            ) : (
                <>
                    <MDButton
                        variant="outlined"
                        color="error"
                        iconOnly
                        circular
                        onClick={() => handleUpdateWithdrawalStatus(withdrawal.w_id, 'rejected')}
                    >
                        <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                    </MDButton>
                    &nbsp;&nbsp;
                    <MDButton
                        variant="outlined"
                        color="success"
                        iconOnly
                        circular
                        onClick={() => handleUpdateWithdrawalStatus(withdrawal.w_id, 'accepted')}
                    >
                        <Icon sx={{ fontWeight: "bold" }}>check</Icon>
                    </MDButton>
                </>
            ),
        })),


        loading: false
    };
}
